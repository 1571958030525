/* 5 - sidebar section - start
==================================================================================================== */
.sidebar-section {
  .widget_title {
    font-size: 24px;
    padding: 0px 30px;
    position: relative;
    margin-left: -30px;
    margin-right: -30px;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid $deep-gray;
    &:before {
      top: 0px;
      left: -1px;
      width: 3px;
      content: "";
      height: 30px;
      position: absolute;
      background-color: $default-primary;
    }
  }

  .widget {
    padding: 30px;
    border-radius: 3px;
    margin-bottom: 50px;
    border: 1px solid $deep-gray;
    &:last-child {
      margin-bottom: 0px;
    }
  }

  /* widget_search - satrt */
  .widget_search {
    .form-item {
      .search-btn {
        top: 0px;
        right: 0px;
        z-index: 1;
        width: auto;
        height: 50px;
        font-size: 20px;
        padding: 0px 25px;
        line-height: 50px;
        position: absolute;
        color: $pure-white;
        text-align: center;
        background-color: $default-primary;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }
  }
  /* widget_search - end */

  /* widget_categories - start */
  .widget_categories {
    > ul {
      > li {
        margin-bottom: 25px;
        &:last-child {
          margin-bottom: 0px;
        }
        > a,
        > .badge {
          font-size: 16px;
          color: $default-color;
          font-weight: $fw-medium;
        }
        > a {
          position: relative;
          &:before {
            left: 0px;
            right: 0px;
            opacity: 0;
            content: "";
            height: 2px;
            width: 100%;
            bottom: -5px;
            position: absolute;
            background-color: $default-primary;
            transition: $default-transition;
          }
        }

        &:hover {
          > a {
            color: $default-primary;
          }
          > .badge {
            color: $default-primary;
          }
          > a {
            &:before {
              opacity: 1;
              bottom: -2px;
            }
          }
        }
      }
    }
  }
  /* widget_categories - end */

  /* widget_cixposttype - start */
  .widget_cixposttype {
    > ul {
      > li {
        margin-bottom: 30px;
        &:last-child {
          margin-bottom: 0px;
        }
        .post-date {
          width: 100%;
          display: table;
          margin-bottom: 15px;
        }
        .item-title {
          color: #535e72;
          font-size: 18px;
          line-height: 26px;
          font-weight: $fw-medium;
        }
        &:hover {
          .item-title {
            color: $default-primary;
          }
        }
      }
    }
  }
  /* widget_cixposttype - end */

  /* widget_tag_cloud - start */
  .widget_tag_cloud {
    > ul {
      margin: -5px;
      > li {
        padding: 5px;
        > a {
          width: auto;
          height: 30px;
          font-size: 14px;
          line-height: 30px;
          padding: 0px 15px;
          text-align: center;
          color: $default-color;
          border: 1px solid $deep-gray;
          &:hover {
            color: $pure-white;
            border-color: $default-primary;
            background-color: $default-primary;
          }
        }
      }
    }
  }
  /* widget_tag_cloud - end */

  /* widget_instagram - start */
  .widget_instagram {
    /* .widget_title {
			&:before {background-color: #e8be84;}
		} */

    > ul {
      margin: -5px;
      > li {
        padding: 5px;
        width: 105px;
        height: 105px;
        > a {
          width: 100%;
          height: 100%;
          display: block;
          overflow: hidden;
          position: relative;
          &:before {
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            opacity: 0;
            content: "";
            position: absolute;
            transition: $default-transition;
            background-color: rgba(0, 0, 0, 0.7);
          }
          &:after {
            top: 50%;
            left: 50%;
            z-index: 1;
            opacity: 0;
            font-size: 60px;
            content: "\ea56";
            color: $pure-white;
            position: absolute;
            font-family: "unicons";
            transform: translate(-50%, -50%);
            transition: $default-transition;
          }
          &:hover {
            &:before {
              opacity: 1;
            }
            &:after {
              opacity: 1;
              font-size: 36px;
            }
          }
        }
      }
    }
  }
  /* widget_instagram - end */

  /* widget_newsletter - start */
  .widget_newsletter {
    .form-item {
      .submit-btn {
        top: 0px;
        right: 0px;
        z-index: 1;
        width: auto;
        height: 50px;
        font-size: 20px;
        padding: 0px 25px;
        line-height: 50px;
        position: absolute;
        color: $pure-white;
        text-align: center;
        background-color: $default-primary;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }
  }
  /* widget_newsletter - end */
}
/* 5 - sidebar section - end
==================================================================================================== */
